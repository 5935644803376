import classes from 'styles/LoginPage.module.scss'
import { FC, useState } from 'react'
import { SignInButton } from '@/components/signin/SignInButton'
import { getProviders, signIn } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useTeamsSDK } from 'use-teams-sdk'
import { GetServerSideProps } from 'next'
import { authOptions } from './api/auth/[...nextauth]'
import { type Session, getServerSession } from 'next-auth'

interface SSP {
  azureProviderId: string
  session: Session | null
}

const DEFAULT_REDIRECT_WHEN_LOGGED_IN = '/app'

const LoginPage: FC<SSP> = ({ azureProviderId }) => {
  const teamsSDKPromise = useTeamsSDK()
  const [loading, setLoading] = useState(false)

  const onSignIn = async () => {
    setLoading(true)
    const teamsSDK = await teamsSDKPromise
    if (!teamsSDK) {
      return signIn(azureProviderId)
    }
    try {
      await teamsSDK.authentication.authenticate({
        url: window.location.origin + '/teams_login',
        width: 400,
        height: 400,
      })
      const params = new URLSearchParams(window.location.search)
      window.location.replace(params.get('next') ?? '/app')
    } catch (e) {
      console.error(e)
    }
  }

  const linkCn = 'cursor-pointer text-violet-900 hover:underline m-2'

  return (
    <div className={classes.body}>
      <div className={classes.card}>
        <Link href="/">
          <Image
            className="rounded cursor-pointer hover:opacity-80 active:opacity-70 transition-opacity"
            alt="Reminders logo"
            src="/logo.png"
            width={36}
            height={36}
          />
        </Link>
        <h1 className={classes.appName}>Reminder</h1>
        <p className="mb-6">To start, you have to log in.</p>
        <SignInButton loading={loading} onSignIn={onSignIn} />
        <p className="mt-4">
          <button className={linkCn} onClick={onSignIn}>
            Register
          </button>
          <a href="/help" target="_blank" className={linkCn}>
            Help
          </a>
          <a href="/" target="_blank" className={linkCn}>
            About
          </a>
          <a className={linkCn} href="mailto:support+reminders@appfluence.com">
            Contact
          </a>
        </p>
      </div>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps<SSP> = async (context) => {
  const session = await getServerSession(context.req, context.res, authOptions)

  let azureProviderId = ''
  try {
    const providers = await getProviders()
    azureProviderId = providers?.['azure-ad']?.id ?? ''
  } catch (err) {}

  const props = { azureProviderId, session }

  if (session) {
    return {
      redirect: {
        destination: context.query.next ?? DEFAULT_REDIRECT_WHEN_LOGGED_IN,
        permanent: false,
      },
      props,
    }
  }

  return { props }
}

export default LoginPage
